import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const PricingPage = () => {
  return (
    <Layout>
      <Seo 
        title="Pricing" 
        description="Thoughtfully priced for destinations of all sizes"
      />
      <section className="mt-5 lg:mt-20">
        <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
          <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">Pricing</h1>
          <p className="text-base font-semibold lg:text-2xl">Considerate pricing for destinations of all sizes.</p>
          <p className="text-base font-semibold lg:text-2xl">Just a quick scan and off they go.</p>
        </div>
        <div className="max-w-screen-lg mx-4 my-20 lg:mx-auto">
          <p className="text-5xl font-extrabold text-center ">We're still finalising our pricing, feel free to reach out and have a chat about it.</p>
        </div>
      </section>
    </Layout>
  )
}

export default PricingPage
